<template>
	<b-card>
		<ProductPopup :data="modalProduct" :is-approved="true" />
		<div class="filters">
			<b-form-group label="Search" class="form-group">
				<b-form-input
					v-model="searchQuery"
					placeholder="search term here"
					autofocus
				/>
			</b-form-group>
		</div>
		<b-table
			ref="refProductsListTable"
			bordered
			hover
			responsive
			class="shadow-sm rounded products-table"
			thead-tr-class="order-main-thead"
			:items="productsList"
			:fields="fields"
			primary-key="id"
			show-empty
			empty-text="No matching records found"
			small
			@row-clicked="onRowClicked"
		>
			<template v-if="false" #cell(actions)="data">
				<b-button
					variant="flat-primary"
					class="btn-icon"
					@click="editProductButton(data.item)"
				>
					<feather-icon size="16" icon="EditIcon" />
				</b-button>
				<b-button
					class="btn-icon"
					variant="flat-danger"
					@click="removeProductButton(data.item)"
				>
					<feather-icon size="16" icon="TrashIcon" />
				</b-button>
			</template>
		</b-table>
		<Pagination
			:fetch-data="getProducts"
			fetch-type="products"
			:total-rows="totalRows"
			:take.sync="take"
		/>
	</b-card>
</template>

<script>
import { BTable, BCard, BFormInput, BFormGroup, BButton } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import ProductPopup from './ProductPopup.vue';
import Pagination from '../components/Pagination/Pagination.vue';

export default {
	components: {
		BTable,
		BCard,
		BButton,
		BFormInput,
		BFormGroup,
		Pagination,
		ProductPopup,
	},
	data() {
		return {
			take: 20,
			skip: 0,
			totalRows: 0,
			productsList: [],
			searchQuery: '',
			modalProduct: null,
			fields: [
				{
					label: 'Name',
					key: 'nameGeo',
					formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
					tdClass: 'nameColumn',
				},
				{
					key: 'price',
					label: 'Price',
					sortable: true,
					formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
				},
				{
					key: 'price',
					label: 'Price',
					sortable: true,
					formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
				},
				{
					key: 'count',
					label: 'Count',
				},
				{
					key: 'percentCommissionValue',
					label: 'Comission',
				},
				{
					label: 'Created at',
					key: 'createdAt',
					sortable: false,
				},
				{
					label: 'Category',
					key: 'productCategory',
					formatter: (value) =>
						`${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
					sortable: false,
				},
				{
					label: 'Sub Category',
					key: 'productSubCategory',
					formatter: (value) =>
						`${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
					sortable: false,
				},
				{
					label: 'Generic Category',
					key: 'productGenericCategory',
					formatter: (value) =>
						`${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
					sortable: false,
				},
				{
					label: 'SKU',
					key: 'upcOrSku',
				},
				{
					label: 'Actions',
					key: 'actions',
				},
			],
		};
	},

	computed: {
		...mapState('products', ['products']),
	},
	watch: {
		searchQuery(newVal) {
			this.getProducts({
				skip: 0,
				take: 20,
				searchKey: newVal,
				productType: 'products',
			});
		},
	},
	created() {
		this.getProducts({
			skip: this.skip,
			take: this.take,
			searchKey: '',
			productType: 'products',
		}).then(() => {
			this.totalRows = this.products.count;
			this.productsList = this.products.products;
			console.log(this.products);
		});
	},
	methods: {
		...mapActions('products', ['getProducts']),
		editProductButton(prod) {
			console.log(prod);
		},
		removeProductButton(prod) {
			console.log(prod);
		},
		onRowClicked(item) {
			this.modalProduct = item;
			// this.$bvModal.show('approved-product-details');
			this.$bvModal.show('product-show-modal');
			console.log(item);
		},
	},
};
</script>

<style>
.filters .form-group {
	max-width: 300px;
}
.products-table th,
.products-table td {
	white-space: nowrap;
}
</style>
